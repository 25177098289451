<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Message</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-md-12">
                <!-- <span>Location</span> -->
                <v-textarea
                  name="input-7-1"
                  label="Messgae"
                  v-model="payload.message"
                ></v-textarea>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="sendMessage()">
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      payload: {
        message: "",
        status: "pending",
        user: null,
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    sendMessage() {
      let payload = {
        message: this.payload.message,
        status: this.payload.status,
        user: this.id,
      };

      let error = undefined;

      if (payload.message.trim() === "") {
        error = "Message cannot be empty";
      }

      if (error) {
        this.$notify({
          title: error,
          icon: "fa fa-exclamation-triangle",
          type: "danger",
        });
      } else {
        this.$store
          .dispatch("setNotification", {
            payload,
          })
          .then(() => {
            this.$notify({
              title: "Success",
              icon: "fa fa-check-circle",
              type: "success",
              message: "Message send successfully",
            });
            this.close();
            this.message = "";
          })
          .catch((err) => {
            this.$notify({
              title: "Failed to send message",
              icon: "fa fa-exclamation-triangle",
              type: "danger",
              message: err.toString(),
            });
          });
      }
    },
  },
  props: {
    dialog: {
      default: false,
    },
    id: null,
  },
};
</script>
