<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>
    <div class="container-fluid">
      <v-app>
        <card>
          <div class="row align-items-center p-3">
            <h2 class="col-6 m-0">Staff Management</h2>
            <div class="col-6 text-end">
              <v-btn
                class="mx-2"
                fab
                @click.prevent="add = !add"
                dark
                small
                color="#4D4B4B"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="col-6">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>

          <div class="table-responsive mt-4">
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="filteredList"
            >
              <template slot="columns">
                <th v-for="(header, index) in tableHeaders" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ index + 1 }}</td>
                <td>{{ row.employee_code }}</td>
                <td>{{ row.username }}</td>
                <td>{{ row.email }}</td>
                <td>{{ row.mobile }}</td>
                <td>
                  <!-- <i class="btn fas fa-eye" @click.prevent="viewProperty"></i> -->
                  <i
                    class="btn fas fa-pen"
                    @click.prevent="editStaff(row.id)"
                  ></i>
                  <i
                    class="btn fas fa-trash text-danger"
                    @click.prevent="deleteStaff({ row, index })"
                  ></i>
                  <i
                    class="btn fas fa-envelope text-primary"
                    @click.prevent="messageStaff(row.id)"
                  ></i>
                </td>
              </template>
            </base-table>
          </div>
          <delete-dialog
            :data="filteredList"
            v-model="deleteItemIndex"
            actionString="deleteStaff"
            item="staff"
            :miscData="{ itemID: selectedItem }"
          >
            <span slot="warning">This staff data will be removed! </span>
          </delete-dialog>
        </card>
      </v-app>
    </div>
    <AddStaff :dialog.sync="add" />
    <EditStaff :dialog.sync="edit" :id.sync="selectedItem" />
    <SendMessage :dialog.sync="msg" :id.sync="selectedItem2" />
  </div>
</template>
<script>
import AddStaff from "./AddStaff.vue";
import EditStaff from "./EditStaff.vue";
import SendMessage from "./SendMessage.vue";
export default {
  name: "Staffs",
  components: {
    AddStaff,
    EditStaff,
    SendMessage,
  },
  data() {
    return {
      add: false,
      edit: false,
      msg: false,
      deleteItemIndex: -1,
      selectedItem: null,
      selectedItem2: null,
      tableHeaders: ["SL", "Employee ID", "Name", "Email", "Mobile", "Actions"],
      filteredList: [],
      search: "",
    };
  },
  methods: {
    editStaff(id) {
      this.selectedItem = id;
      this.edit = !this.edit;
    },
    messageStaff(id) {
      this.selectedItem2 = id;
      this.msg = !this.msg;
    },
    deleteStaff({ row, index }) {
      this.deleteItemIndex = index;
      this.selectedItem = row.id;
    },
    fetchStaffList() {
      this.$store.dispatch("fetchStaffList").catch((err) => {
        this.$notify({
          title: "Failed to fetch staff list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
  },
  computed: {
    staffList() {
      return this.$store.state.staffStore.staffs;
    },
  },
  watch: {
    add() {
      this.fetchStaffList();
    },
    edit() {
      this.fetchStaffList();
    },
    staffList() {
      this.filteredList = [...this.staffList];
    },
    search() {
      if (this.search.trim() !== "") {
        this.filteredList = this.staffList.filter((item) =>
          item.username.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        this.filteredList = [...this.staffList];
      }
    },
  },
  mounted() {
    this.fetchStaffList();
  },
};
</script>
<style scoped>
.test {
  color: blue;
}

.test:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
