var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"py-6",style:({ background: '#F7F8FD' })},[_c('div',{staticClass:"row"})]),_c('div',{staticClass:"container-fluid"},[_c('v-app',[_c('card',{staticClass:"border border-black"},[_c('div',{staticClass:"row align-items-center p-3"},[_c('h2',{staticClass:"col-md-6 m-0"},[_vm._v("Products")]),_c('div',{staticClass:"col-md-5 text-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","small":"","fab":"","color":_vm.list ? 'teal' : 'grey'},on:{"click":function($event){_vm.list = true}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","fab":"","dark":"","small":"","color":_vm.list ? 'grey' : 'teal'},on:{"click":function($event){_vm.list = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-view-grid ")])],1)],1),_c('div',{staticClass:"col-md-1 text-end"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"#4D4B4B"},on:{"click":function($event){$event.preventDefault();_vm.add = !_vm.add}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]),_c('div',{staticClass:"col-6"},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.list)?_c('div',{staticClass:"table-responsive mt-4"},[_c('base-table',{staticClass:"align-items-center",attrs:{"thead-classes":"thead-light","type":"hover","data":_vm.filteredList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"col-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('v-img',{attrs:{"height":"50","width":"50","src":row.image
                          ? row.image
                          : 'https://cdn-icons-png.flaticon.com/512/1250/1250649.png'}})],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(row.name))])])])]),_c('td',[_vm._v(_vm._s(row.code))]),_c('td',[_vm._v("₹ "+_vm._s(row.amount))]),_c('td',{staticClass:"text-wrap text-center"},[_vm._v(_vm._s(row.description))]),_c('td',[_c('i',{staticClass:"btn fas fa-pen",on:{"click":function($event){return _vm.editProduct(row.id)}}}),_c('i',{staticClass:"btn fas fa-trash text-danger",on:{"click":function($event){$event.preventDefault();return _vm.deleteProduct({ row: row, index: index })}}})])]}}],null,false,477082226)},[_c('template',{slot:"columns"},_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(header)+" ")])}),0)],2)],1):_c('div',{staticClass:"d-flex justify-content-center flex-wrap align-items-center mt-2 mx-2"},_vm._l((_vm.filteredList),function(item,idx){return _c('v-card',{key:idx,staticClass:"mx-auto col-3 my-12",attrs:{"max-width":"250"}},[_c('v-img',{attrs:{"height":"200","src":item.image
                  ? item.image
                  : 'https://cdn-icons-png.flaticon.com/512/1250/1250649.png?w=740'}}),_c('v-card-title',[_vm._v(_vm._s(item.name))]),_c('v-card-text',[_c('div',{staticClass:"my-1 text-subtitle-1"},[_vm._v("₹ "+_vm._s(item.amount))])])],1)}),1),_c('delete-dialog',{attrs:{"data":_vm.productList,"actionString":"deleteProduct","item":"product","miscData":{ itemID: _vm.selectedItem }},model:{value:(_vm.deleteItemIndex),callback:function ($$v) {_vm.deleteItemIndex=$$v},expression:"deleteItemIndex"}},[_c('span',{attrs:{"slot":"warning"},slot:"warning"},[_vm._v("This product data will be removed! ")])])],1)],1)],1),_c('AddProduct',{attrs:{"dialog":_vm.add},on:{"update:dialog":function($event){_vm.add=$event}}}),_c('EditProduct',{attrs:{"dialog":_vm.edit,"id":_vm.selectedItem},on:{"update:dialog":function($event){_vm.edit=$event},"update:id":function($event){_vm.selectedItem=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }