<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Edit Staff</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Employee ID</span>
                <v-text-field
                  class="mt-4"
                  fab
                  type="text"
                  v-model="staffData.employee_code"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Staff Email</span>
                <v-text-field
                  class="mt-4"
                  v-model="staffData.email"
                  fab
                  type="email"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Staff Mobile</span>
                <v-text-field
                  class="mt-4"
                  v-model="staffData.mobile"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>

            <!-- <div class="row d-flex align-items-center">
              <div class="col-md-12">
                <span>Employee Code</span>
                <v-text-field class="mt-4" fab type="text"></v-text-field>
              </div>
            </div> -->

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select Distributor</span>
                <v-autocomplete
                  deletable-chips
                  multiple
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  return-object
                  v-model="staffData.distributors"
                  item-text="name"
                  :items="distributorsList"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select Distributor Route</span>
                <v-autocomplete
                  deletable-chips
                  multiple
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  return-object
                  v-model="staffData.routes"
                  item-text="name"
                  :items="routeList"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select ASM</span>
                <v-autocomplete
                  deletable-chips
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  v-model="staffData.asm"
                  item-text="name"
                  return-object
                  :items="asmList"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-6">
                <span>Staff Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="staffData.username"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Password</span>
                <v-text-field
                  class="mt-4"
                  v-model="staffData.password"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveStaff()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      staffData: {
        email: "",
        mobile: "",
        username: "",
        password: "",
        routes: [],
        distributors: [],
        asm: [],
        employee_code: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveStaff() {
      let distributors = this.staffData.distributors.map((item) => item?.id);
      let routes = this.staffData.routes.map((item) => item?.id);
      console.log(routes);
      let asm = this.staffData.asm?.id || this.staffData.asm;

      let error = undefined;

      let payload = {};

      payload = {
        employee_code: this.staffData.employee_code,
        email: this.staffData.email,
        username: this.staffData.username,
        password: this.staffData.password,
        mobile: this.staffData.mobile,
        distributors: distributors,
        routes: routes,
        asm: asm,
      };

      console.log(payload);

      if (distributors.length === 0) {
        error = "Please select a distributor";
      } else if (routes.length === 0) {
        error = "Please select a route";
      } else if (asm === undefined) {
        error = "Please select an asm";
      } else if (payload.username.trim() === "") {
        error = "Please enter a valid username";
      } else if (payload.password === undefined || payload.password === "") {
        error = "Please enter a valid password";
      } else if (payload.email.trim() === "") {
        error = "Please enter a valid email address";
      } else if (payload.mobile.trim() === "") {
        error = "Please enter a valid mobile number";
      }

      if (error) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: error,
        });
        return;
      }

      this.$store
        .dispatch("setStaffDetails", {
          payload,
          staffID: this.id,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to edit staff",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchStaffDetails() {
      this.$store
        .dispatch("fetchStaffDetails", { staffID: this.id })
        .then(() => {
          this.prepareStaff();
        })
        .catch((err) => {
          this.$notify({
            title: "Failed to fetch staff details",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    prepareStaff() {
      this.staffData = JSON.parse(JSON.stringify(this.staffDetails));

      console.log("preparing: ", this.staffData);
      let routes = this.staffData.routes.map((item) =>
        this.routeList.find((ro) => ro.id === item)
      );
      let distributors = this.staffData.distributors.map((item) =>
        this.distributorsList.find((dod) => dod.id === item)
      );
      let asm = this.asmList.find((item) => item.id === this.staffData.asm);

      this.staffData.routes = routes;
      this.staffData.distributors = distributors;
      this.staffData.asm = asm;
    },
    fetchDistributorList() {
      this.$store.dispatch("fetchDistributorList").catch((err) => {
        this.$notify({
          title: "Failed to fetch distributors list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    fetchAsmList() {
      this.$store.dispatch("fetchAsmList").catch((err) => {
        this.$notify({
          title: "Failed to fetch asm list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
  },
  computed: {
    staffDetails() {
      return this.$store.state.staffStore.staffDetails;
    },
    asmList() {
      return this.$store.state.asmStore.asmList;
    },
    distributorsList() {
      return this.$store.state.distributorStore.distributors;
    },
    routeList() {
      return this.$store.state.routeStore.routes;
    },
  },
  props: {
    dialog: {
      default: false,
    },
    id: null,
  },
  watch: {
    id() {
      this.fetchStaffDetails();
    },
    dialog() {
      this.fetchStaffDetails();
      this.fetchDistributorList();
      this.fetchRouteList();
      this.fetchAsmList();
    },
  },
};
</script>
