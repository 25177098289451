<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>
    <div class="container-fluid">
      <v-app>
        <card>
          <div class="row align-items-center p-3">
            <h2 class="col-6 m-0">Shops {{ "(" + shopCount + ")" }}</h2>
          </div>

          <div class="col-6">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>

          <div class="table-responsive mt-4">
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="filteredList"
            >
              <template slot="columns">
                <th>SL</th>
                <th v-for="(header, index) in tableHeaders" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                <td>{{ row.name }}</td>
                <td>{{ row.creator }}</td>
                <td>{{ row.mobile }}</td>
                <td>{{ row.address }}</td>
                <td>{{ row.latitude }}</td>
                <td>{{ row.longitude }}</td>
                <td>
                  <i @click="editShop(row)" class="btn fas fa-pen"></i>
                  <div
                    @click.prevent="searchItem(row)"
                    class="btn btn-sm btn-outline-primary"
                  >
                    <i class="mdi mdi-map-marker-radius text-black"></i>
                  </div>
                </td>
              </template>
            </base-table>
          </div>
          <base-pagination
            class="mt-3"
            :perPage="pageSize"
            :total="shopCount"
            v-model="currentPage"
            align="center"
          />
        </card>
      </v-app>
    </div>
    <EditShop
      :dialog.sync="edit"
      :id.sync="selectedID"
      :name.sync="selectedShop"
    />
  </div>
</template>
<script>
import EditShop from "./EditShop.vue";
export default {
  name: "shops",
  components: {
    EditShop,
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      offset: 1,

      edit: false,
      selectedID: null,
      selectedShop: "",

      tableHeaders: [
        "Name",
        "Added Staff",
        "Mobile",
        "Address",
        "Longitude",
        "Latitude",
        "Actions",
      ],
      filteredList: [],
      search: "",
    };
  },
  methods: {
    editShop(row) {
      console.log(row);
      this.selectedID = row.id;
      this.selectedShop = row.name;
      this.edit = !this.edit;
    },
    fetchShopList() {
      this.$store
        .dispatch("fetchShopList", {
          currentPage: this.offset,
          pageSize: this.pageSize,
          search: this.search,
        })
        .catch((err) => {
          this.$notify({
            title: "Failed to fetch shop list",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchStaffList() {
      this.$store.dispatch("fetchStaffList").catch((err) => {
        this.$notify({
          title: "Failed to fetch staff list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    searchItem(item) {
      window
        .open(
          `http://maps.google.com/maps?z=12&t=m&q=loc:${item.latitude}+${item.longitude}`,
          "_blank"
        )
        .focus();
    },
  },
  computed: {
    shopList() {
      return this.$store.state.staffStore.shops;
    },
    staffList() {
      return this.$store.state.staffStore.staffs;
    },
    shopCount() {
      return this.$store.state.staffStore.shopCount;
    },
  },
  watch: {
    shopList() {
      this.filteredList = [...this.shopList];
    },
    search() {
      this.currentPage = 1;
      this.fetchShopList();
      // if (this.search.trim() !== "") {
      //   this.currentPage = 1;
      //   this.filteredList = this.shopList.filter((item) =>
      //     item.name.toUpperCase().includes(this.search.toUpperCase())
      //   );
      // } else {
      //   this.filteredList = [...this.shopList];
      // }
    },
    currentPage() {
      this.offset = (this.currentPage - 1) * this.pageSize + 1;
      this.fetchShopList();
    },
  },
  mounted() {
    this.fetchShopList();
    this.fetchStaffList();
  },
};
</script>
<style scoped>
.test {
  color: blue;
}

.test:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
