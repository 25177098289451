var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({ background: '#F7FAFC' })},[_c('v-toolbar',{staticClass:"bg-test",attrs:{"dark":""}},[_vm._v("Add Staff")]),_c('v-card',{staticClass:"mx-5 my-8 py-4 px-5"},[_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-12"},[_c('span',[_vm._v("Employee ID")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"fab":"","type":"text"},model:{value:(_vm.staffData.employee_code),callback:function ($$v) {_vm.$set(_vm.staffData, "employee_code", $$v)},expression:"staffData.employee_code"}})],1),_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Staff Email")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"fab":"","type":"email"},model:{value:(_vm.staffData.email),callback:function ($$v) {_vm.$set(_vm.staffData, "email", $$v)},expression:"staffData.email"}})],1),_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Staff Mobile")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"fab":"","type":"tel"},model:{value:(_vm.staffData.mobile),callback:function ($$v) {_vm.$set(_vm.staffData, "mobile", $$v)},expression:"staffData.mobile"}})],1)]),_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-12"},[_c('span',[_vm._v("Select Distributor")]),_c('v-autocomplete',{attrs:{"deletable-chips":"","multiple":"","menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: true,
                },"small-chips":"","return-object":"","item-text":"name","items":_vm.distributorsList},model:{value:(_vm.staffData.distributors),callback:function ($$v) {_vm.$set(_vm.staffData, "distributors", $$v)},expression:"staffData.distributors"}})],1)]),_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-12"},[_c('span',[_vm._v("Select Distributor Route")]),_c('v-autocomplete',{attrs:{"deletable-chips":"","multiple":"","menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: true,
                },"small-chips":"","item-text":"name","return-object":"","items":_vm.routeList},model:{value:(_vm.staffData.routes),callback:function ($$v) {_vm.$set(_vm.staffData, "routes", $$v)},expression:"staffData.routes"}})],1)]),_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-12"},[_c('span',[_vm._v("Select ASM")]),_c('v-autocomplete',{attrs:{"deletable-chips":"","menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: true,
                },"small-chips":"","items":_vm.asmList,"return-object":"","item-text":"name"},model:{value:(_vm.staffData.asm),callback:function ($$v) {_vm.$set(_vm.staffData, "asm", $$v)},expression:"staffData.asm"}})],1)]),_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Staff Name")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"fab":"","type":"text"},model:{value:(_vm.staffData.username),callback:function ($$v) {_vm.$set(_vm.staffData, "username", $$v)},expression:"staffData.username"}})],1),_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Password")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"fab":"","type":"text"},model:{value:(_vm.staffData.password),callback:function ($$v) {_vm.$set(_vm.staffData, "password", $$v)},expression:"staffData.password"}})],1)])]),_c('v-card-actions',{attrs:{"justify":"end"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},nativeOn:{"click":function($event){return _vm.saveStaff()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }