<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Add Product</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Product Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="productData.name"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-md-6">
                <span>Product Code</span>
                <v-text-field
                  class="mt-4"
                  v-model="productData.code"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-md-6">
                <span>Product Amount</span>
                <v-text-field
                  class="mt-4"
                  placeholder="₹"
                  fab
                  v-model="productData.amount"
                  type="number"
                ></v-text-field>
              </div>
            </div>

            <div class="col-md-12">
              <v-textarea
                name="input-7-1"
                label="Description"
                v-model="productData.description"
              ></v-textarea>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Product Image</span>
                <v-file-input
                  label="File input"
                  filled
                  accept="image/*"
                  class="mt-4"
                  v-model="productData.image"
                  prepend-icon="mdi-camera"
                ></v-file-input>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveProduct()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      productData: {
        name: "",
        code: "",
        amount: null,
        image: "",
        description: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveProduct() {
      this.formData = new FormData();
      this.formData.append("name", this.productData.name);
      this.formData.append("code", this.productData.code);
      this.formData.append("amount", this.productData.amount);
      this.formData.append("description", this.productData.description);

      if (this.productData.image !== "") {
        this.formData.append("image", this.productData.image);
      }

      this.$store
        .dispatch("setProductDetails", {
          payload: this.formData,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to add product",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
  },
  computed: {},
  mounted() {},
  watch: {
    dialog() {
      (this.productData.name = ""),
        (this.productData.code = ""),
        (this.productData.amount = null);
      this.productData.image = "";
    },
  },
  props: {
    dialog: {
      default: false,
    },
  },
};
</script>
