<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>
    <div class="container-fluid">
      <v-app>
        <card>
          <div class="row align-items-center p-3">
            <h2 class="col-6 m-0">Routes</h2>
            <div class="col-6 text-end">
              <v-btn
                class="mx-2"
                fab
                @click.prevent="add = !add"
                dark
                small
                color="#4D4B4B"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="col-6">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>

          <div class="table-responsive mt-4">
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="filteredList"
            >
              <template slot="columns">
                <th v-for="(header, index) in tableHeaders" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ index + 1 }}</td>
                <td>{{ row.name }}</td>
                <td>
                  <i class="btn fas fa-pen" @click="editRoute(row)"></i>
                  <i
                    class="btn fas fa-trash text-danger"
                    @click.prevent="deleteRoute({ row, index })"
                  ></i>
                </td>
              </template>
            </base-table>
          </div>
          <delete-dialog
            :data="routeList"
            v-model="deleteItemIndex"
            actionString="deleteRoute"
            item="route"
            :miscData="{ itemID: selectedID }"
          >
            <span slot="warning">This route will be removed! </span>
          </delete-dialog>
        </card>
      </v-app>
    </div>
    <AddRoute :dialog.sync="add" />
    <EditRoute
      :dialog.sync="edit"
      :id.sync="selectedID"
      :name.sync="selectedRoute"
    />
  </div>
</template>
<script>
import AddRoute from "./AddRoute.vue";
import EditRoute from "./EditRoute.vue";

export default {
  name: "routes",
  components: {
    AddRoute,
    EditRoute,
  },
  data() {
    return {
      add: false,
      edit: false,
      selectedID: null,
      selectedRoute: "",
      tableHeaders: ["SL", "Name", "Actions"],
      deleteItemIndex: -1,
      filteredList: [],
      search: "",
    };
  },
  methods: {
    editRoute(row) {
      console.log(row);
      this.selectedID = row.id;
      this.selectedRoute = row.name;
      this.edit = !this.edit;
    },
    deleteRoute({ row, index }) {
      this.deleteItemIndex = index;
      this.selectedID = row.id;
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
  },
  computed: {
    routeList() {
      return this.$store.state.routeStore.routes;
    },
  },
  watch: {
    add() {
      this.fetchRouteList();
    },
    edit() {
      this.fetchRouteList();
    },
    routeList() {
      this.filteredList = [...this.routeList];
    },
    search() {
      if (this.search.trim() !== "") {
        this.filteredList = this.routeList.filter((item) =>
          item.name.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        this.filteredList = [...this.routeList];
      }
    },
  },
  mounted() {
    this.fetchRouteList();
  },
};
</script>
<style scoped>
.test {
  color: blue;
}

.test:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
