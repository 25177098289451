<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Add Route</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-md-12">
                <!-- <span>Location</span> -->
                <v-textarea
                  name="input-7-1"
                  label="Route"
                  value=""
                  v-model="route"
                ></v-textarea>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveRoute()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      route: "",
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveRoute() {
      let payload = {
        name: this.route,
      };

      this.$store
        .dispatch("setRouteDetails", {
          payload,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to add route",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
  },
  watch: {
    dialog() {
      this.route = "";
    },
  },
  props: {
    dialog: {
      default: false,
    },
  },
};
</script>
