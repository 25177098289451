<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Edit Distributor</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <span>Name</span>
                <v-text-field
                  v-model="distributorData.name"
                  class="mt-4"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Contact Number</span>
                <v-text-field
                  v-model="distributorData.number"
                  class="mt-4"
                  fab
                  type="mobile"
                ></v-text-field>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-md-12">
                <!-- <span>Location</span> -->
                <v-textarea
                  name="input-7-1"
                  label="Location"
                  v-model="distributorData.location"
                ></v-textarea>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Routes</span>
                <v-autocomplete
                  deletable-chips
                  multiple
                  small-chips
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  v-model="distributorData.routes"
                  :items="routeList"
                  item-text="name"
                  return-object
                ></v-autocomplete>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveProduct()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      distributorData: {
        name: "",
        number: "",
        location: "",
        routes: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveProduct() {
      let routes = this.distributorData.routes.map((item) => item.id);

      let payload = {
        name: this.distributorData.name,
        number: this.distributorData.number,
        location: this.distributorData.location,
        routes: routes,
      };

      this.$store
        .dispatch("setDistributorDetails", {
          payload,
          distributorID: this.id,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to edit distributor",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchDistributorDetails() {
      this.$store
        .dispatch("fetchDistributorDetails", { distributorID: this.id })
        .then(() => {
          this.prepareDistributor();
        })
        .catch((err) => {
          this.$notify({
            title: "Failed to fetch distributor details",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    prepareDistributor() {
      this.distributorData = JSON.parse(
        JSON.stringify(this.distributorDetails)
      );
      let routes = this.distributorData.routes.map((item) =>
        this.routeList.find((ro) => ro.id === item)
      );
      this.distributorData.routes = routes;
    },
  },
  computed: {
    distributorDetails() {
      return this.$store.state.distributorStore.distributorDetails;
    },
    routeList() {
      return this.$store.state.routeStore.routes;
    },
  },
  mounted() {
    this.fetchRouteList();
  },
  props: {
    dialog: {
      default: false,
    },
    id: null,
  },
  watch: {
    id() {
      this.fetchDistributorDetails();
    },
    dialog() {
      this.fetchDistributorDetails();
    },
  },
};
</script>
