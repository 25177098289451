<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>

    <div class="container-fluid">
      <v-app>
        <card class="border border-black">
          <div class="row align-items-center p-3">
            <h2 class="col-md-6 m-0">Products</h2>
            <div class="col-md-5 text-end">
              <v-btn
                class="ma-2"
                outlined
                small
                @click="list = true"
                fab
                :color="list ? 'teal' : 'grey'"
              >
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                fab
                @click="list = false"
                dark
                small
                :color="list ? 'grey' : 'teal'"
              >
                <v-icon dark> mdi-view-grid </v-icon>
              </v-btn>
            </div>
            <div class="col-md-1 text-end">
              <v-btn
                class="mx-2"
                fab
                @click.prevent="add = !add"
                dark
                small
                color="#4D4B4B"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="col-6">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>

          <div v-if="list" class="table-responsive mt-4">
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="filteredList"
            >
              <template slot="columns">
                <th v-for="(header, index) in tableHeaders" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ index + 1 }}</td>
                <td class="col-3">
                  <div class="row">
                    <div class="col-3">
                      <v-img
                        height="50"
                        width="50"
                        :src="
                          row.image
                            ? row.image
                            : 'https://cdn-icons-png.flaticon.com/512/1250/1250649.png'
                        "
                      ></v-img>
                    </div>
                    <div class="col">
                      <div class="col">{{ row.name }}</div>
                    </div>
                  </div>
                </td>
                <td>{{ row.code }}</td>
                <td>₹ {{ row.amount }}</td>
                <td class="text-wrap text-center">{{ row.description }}</td>
                <td>
                  <i class="btn fas fa-pen" @click="editProduct(row.id)"></i>
                  <i
                    class="btn fas fa-trash text-danger"
                    @click.prevent="deleteProduct({ row, index })"
                  ></i>
                </td>
              </template>
            </base-table>
          </div>

          <div
            v-else
            class="d-flex justify-content-center flex-wrap align-items-center mt-2 mx-2"
          >
            <v-card
              v-for="(item, idx) of filteredList"
              :key="idx"
              class="mx-auto col-3 my-12"
              max-width="250"
            >
              <v-img
                height="200"
                :src="
                  item.image
                    ? item.image
                    : 'https://cdn-icons-png.flaticon.com/512/1250/1250649.png?w=740'
                "
              ></v-img>

              <!-- src="https://cdn-icons-png.flaticon.com/512/1250/1250649.png?w=740" -->
              <!-- src="https://help.rangeme.com/hc/article_attachments/360006928633/what_makes_a_good_product_image.jpg"  -->

              <v-card-title>{{ item.name }}</v-card-title>

              <v-card-text>
                <div class="my-1 text-subtitle-1">₹ {{ item.amount }}</div>
              </v-card-text>
            </v-card>
          </div>
          <delete-dialog
            :data="productList"
            v-model="deleteItemIndex"
            actionString="deleteProduct"
            item="product"
            :miscData="{ itemID: selectedItem }"
          >
            <span slot="warning">This product data will be removed! </span>
          </delete-dialog>
        </card>
      </v-app>
    </div>
    <AddProduct :dialog.sync="add" />
    <EditProduct :dialog.sync="edit" :id.sync="selectedItem" />
  </div>
</template>
<script>
import AddProduct from "./AddProduct.vue";
import EditProduct from "./EditProduct.vue";
export default {
  name: "products",
  components: {
    AddProduct,
    EditProduct,
  },
  data() {
    return {
      add: false,
      edit: false,
      list: true,
      selectedItem: null,
      deleteItemIndex: -1,
      search: "",
      tableHeaders: [
        "SL",
        "Product",
        "Product Code",
        "Amount",
        "Description",
        "Actions",
      ],
      filteredList: [],
    };
  },
  methods: {
    viewProduct() {
      this.$router.push("Products/sdfsdfsdfsd/dashboard");
    },
    editProduct(id) {
      this.selectedItem = id;
      this.edit = !this.edit;
    },
    deleteProduct({ row, index }) {
      this.deleteItemIndex = index;
      this.selectedItem = row.id;
    },
    fetchProductList() {
      this.$store.dispatch("fetchProductList").catch((err) => {
        this.$notify({
          title: "Failed to fetch products list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
  },
  computed: {
    productList() {
      return this.$store.state.productStore.products;
    },
    loader() {
      return this.$store.state.displayLoader;
    },
  },
  watch: {
    add() {
      this.fetchProductList();
    },
    edit() {
      this.fetchProductList();
    },
    productList() {
      this.filteredList = [...this.productList];
    },
    search() {
      if (this.search.trim() !== "") {
        this.filteredList = this.productList.filter((item) =>
          item.name.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        this.filteredList = [...this.productList];
      }
    },
  },
  mounted() {
    this.fetchProductList();
  },
};
</script>
<style scoped>
.test {
  color: blue;
}

.test:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
