<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="900"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Edit Shop</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Shop Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="shopData.name"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-md-6">
                <span>Contact Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="shopData.contact_person"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-md-6">
                <span>Contact Number</span>
                <v-text-field
                  class="mt-4"
                  v-model="shopData.mobile"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>

            <div class="col-md-12">
              <v-textarea
                name="input-7-1"
                label="Address"
                v-model="shopData.address"
              ></v-textarea>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-md-6">
                <span>GST</span>
                <v-text-field
                  class="mt-4"
                  v-model="shopData.gst"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-md-6">
                <span>Place</span>
                <v-text-field
                  class="mt-4"
                  v-model="shopData.place"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select Distributor</span>
                <v-autocomplete
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  return-object
                  v-model="shopData.distributor"
                  item-text="name"
                  :items="distributorsList"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select Route</span>
                <v-autocomplete
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  return-object
                  v-model="shopData.route"
                  item-text="name"
                  :items="routeList"
                ></v-autocomplete>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveShop()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      shopData: {
        creator: "",
        name: "",
        contact_person: "",
        mobile: "",
        address: "",
        gst: "",
        longitude: "",
        latitude: "",
        place: "",
        distributor: [],
        route: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveShop() {
      let distributor =
        this.shopData.distributor?.id || this.shopData.distributor;

      let route = this.shopData.route?.id || this.shopData.route;

      let payload = {
        creator: this.shopData.creator,
        name: this.shopData.name,
        contact_person: this.shopData.contact_person,
        mobile: this.shopData.mobile,
        address: this.shopData.address,
        gst: this.shopData.gst,
        longitude: this.shopData.longitude,
        latitude: this.shopData.latitude,
        place: this.shopData.place,
        distributor: distributor,
        route: route,
      };

      console.log(payload);

      this.$store
        .dispatch("setShopDetails", {
          payload,
          shopID: this.id,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to edit shop",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchShopDetails() {
      this.$store
        .dispatch("fetchShopDetails", { shopID: this.id })
        .then(() => {
          this.prepareShop();
        })
        .catch((err) => {
          this.$notify({
            title: "Failed to fetch shop details",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    prepareShop() {
      this.shopData = JSON.parse(JSON.stringify(this.shopDetails));

      console.log("preparing: ", this.shopData);

      let distributor = this.distributorsList.find(
        (item) => item.id === this.shopData.distributor
      );
      let route = this.routeList.find(
        (item) => item.id === this.shopData.route
      );

      this.shopData.route = route;
      this.shopData.distributor = distributor;
      console.log(this.shopData);
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    fetchDistributorList() {
      this.$store.dispatch("fetchDistributorList").catch((err) => {
        this.$notify({
          title: "Failed to fetch distributors list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
  },
  computed: {
    shopDetails() {
      return this.$store.state.staffStore.shopDetails;
    },
    distributorsList() {
      return this.$store.state.distributorStore.distributors;
    },
    routeList() {
      return this.$store.state.routeStore.routes;
    },
  },
  props: {
    dialog: {
      default: false,
    },
    id: null,
  },
  watch: {
    id() {
      this.fetchShopDetails();
      this.fetchDistributorList();
      this.fetchRouteList();
    },
  },
  mounted() {},
};
</script>
