<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Add Distributor</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <span>Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="distributorData.name"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Contact Number</span>
                <v-text-field
                  class="mt-4"
                  v-model="distributorData.number"
                  fab
                  type="mobile"
                ></v-text-field>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-md-12">
                <!-- <span>Location</span> -->
                <v-textarea
                  name="input-7-1"
                  label="Location"
                  v-model="distributorData.location"
                ></v-textarea>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Routes</span>
                <v-autocomplete
                  deletable-chips
                  multiple
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  :items="routeList"
                  v-model="distributorData.routes"
                  item-text="name"
                  return-object
                ></v-autocomplete>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveDistributor()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      distributorData: {
        name: "",
        number: "",
        location: "",
        routes: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    saveDistributor() {
      console.log(this.distributorData);

      let routes = this.distributorData.routes.map(item=>item.id)

      let payload = {
        name:this.distributorData.name,
        number:this.distributorData.number,
        location:this.distributorData.location,
        routes:routes
      }

      this.$store
        .dispatch("setDistributorDetails", {
          payload,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to add distributor",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
  },
  computed: {
    routeList() {
      return this.$store.state.routeStore.routes;
    },
  },
  props: {
    dialog: {
      default: false,
    },
  },
  mounted() {
    this.fetchRouteList();
  },
  watch: {
    dialog() {
      (this.distributorData.name = ""),
        (this.distributorData.number = ""),
        (this.distributorData.location = "");
      this.distributorData.routes = [];
    },
  },
};
</script>
